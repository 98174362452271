import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { toast, Notification } from 'components/ui'

const config = {
    baseURL: 'https://api.shipcluescargo.com',
    authentication: {
        refreshToken: '/auth/refresh-token', 
    },
}

let refreshTokenRequest = null 
let isRedirecting = false 
let expirationTimeout = null 
    
// Check if the token is expired
const isTokenExpired = (token) => {
    try {
        const decodedToken = jwtDecode(token)
        const currentTime = Date.now() / 1000 
        return decodedToken.exp < currentTime + 60 // Add 1 minute for testing
    } catch (error) {
        console.error('Error decoding token:', error)
        return true 
    }
}

// Handle token expiration
const handleTokenExpiration = () => {
    if (isRedirecting) return
    isRedirecting = true

    // Show notification with "Log in" button
    toast.push(
        <Notification closable type="danger" duration={6000}>
            <div>
                <p>Your session has expired. Please sign in again.</p>
                <button
                    onClick={() => {
                        // Clear tokens and navigate to sign-in
                        localStorage.clear();
                        sessionStorage.clear();
                        window.location.href = '/sign-in';
                    }}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                    Log in
                </button>
            </div>
        </Notification>
    );

    // Schedule automatic logout after showing the notification              
    expirationTimeout = setTimeout(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refresh-token');
        refreshTokenRequest = null;

        // Redirect to sign-in page
        localStorage.clear();
        sessionStorage.clear();
        if (window.location.pathname !== '/sign-in') {
            window.location.href = '/sign-in';
        }
    }, 5400000); // Check every 1.5 hours
};

// Start periodic token expiry check
const startTokenExpiryCheck = () => {
    setInterval(() => {
        const token = localStorage.getItem('accessToken')
        if (token && isTokenExpired(token)) {
            handleTokenExpiration()
        }
    }, 5400000) // Check every 1.5 hours
}

// Create an Axios instance with base URL
const axiosInstance = axios.create({
    baseURL: config.baseURL,
})

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken')

        if (token) {
            if (isTokenExpired(token)) {
                handleTokenExpiration()
                return Promise.reject(new Error('Token expired'))
            }
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => Promise.reject(error)
)

// Response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true

            try {
                if (!refreshTokenRequest) {
                    refreshTokenRequest = refreshToken()
                }
                const newAccessToken = await refreshTokenRequest
                refreshTokenRequest = null

                if (newAccessToken) {
                    localStorage.setItem('accessToken', newAccessToken)
                    originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
                    return axiosInstance(originalRequest)
                } else {
                    handleTokenExpiration()
                }
            } catch (refreshError) {
                handleTokenExpiration()
            }
        }

        return Promise.reject(error)
    }
)

// Refresh token function
const refreshToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refresh-token')
        if (!refreshToken) throw new Error('No refresh token available')

        const response = await axios.post(
            `${config.baseURL}${config.authentication.refreshToken}`,
            {
                refresh: refreshToken,
            }
        )
        return response.data.access
    } catch (error) {
        console.error('Token refresh failed:', error)
        return null
    }
}

// Listen for localStorage changes (e.g., multi-tab logout)
window.addEventListener('storage', (event) => {
    if (event.key === 'accessToken' && !event.newValue) {
        if (!isRedirecting) {
            clearTimeout(expirationTimeout) // Clear timeout on logout
            handleTokenExpiration()
        }
    }
})

// Start periodic token expiry check
startTokenExpiryCheck()

export default axiosInstance
