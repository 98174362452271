import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { config } from 'utils/apiEndPoint';

// Function to log in a user and store the tokens
export async function logIn(payload) {
    try {
        const response = await axios.post(`${config?.authentication?.signIn}`, payload);

        // Store the tokens in localStorage
        localStorage.setItem('accessToken', response?.data?.access);
        localStorage.setItem('refresh-token', response?.data?.refresh);

        return response;
    } catch (e) {
        console.error('Error in signIn API:', e);
    }
}

// Function to create a new user and store the tokens
export async function createNewUser(payload) {
    try {
        const response = await axios.post(`${config?.authentication?.signUp}`, payload);

        // Store the tokens in localStorage
        localStorage.setItem('accessToken', response?.data?.access);
        localStorage.setItem('refresh-token', response?.data?.refresh);
        return response; // Optionally return response if needed
    } catch (e) {
        console.error('Error in signUp API:', e);
    }
}

// Function to get the current logged-in user by decoding the access token
export function getCurrentUser() {
    try {
        const token = localStorage.getItem('accessToken'); // Fetch the access token
        if (token) {
            return jwtDecode(token); // Decode the token to get user data
        }
        return null; 
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
}

// Function to check if the access token is expired
export function isTokenExpired(token) {
    try {
        const decodedToken = jwtDecode(token); 
        const currentTime = Date.now() / 1000; 
        const expirationTime = decodedToken.exp; 

        return expirationTime < currentTime + 60; // Add 1 minute for testing
    } catch (error) {
        console.error("Error decoding token:", error);
        return true; 
    }
}

// Function to handle token expiration, logout, and redirect to sign-in page
export const handleTokenExpiration = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refresh-token');
    if (window.location.pathname !== '/sign-in') {
        window.location.href = '/sign-in'; // Redirect to sign-in page
    }
}

// Function to refresh the access token using the refresh token
export const refreshToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refresh-token');
        if (!refreshToken) throw new Error("No refresh token available");

        const response = await axios.post(config?.authentication?.refreshToken, { refresh: refreshToken });

        // Store the new access token
        localStorage.setItem('accessToken', response?.data?.access);
        return response?.data?.access; // Return new access token
    } catch (error) {
        console.error("Token refresh failed:", error);
        return null; 
    }
}

// Check if the refresh token is expired or invalid
export const isRefreshTokenExpired = (refreshToken) => {
    try {
        const decodedRefreshToken = jwtDecode(refreshToken); 
        const currentTime = Date.now() / 1000; 
        const expirationTime = decodedRefreshToken.exp; 
        return expirationTime < currentTime; 
    } catch (error) {
        console.error("Error decoding refresh token:", error);
        return true; 
    }
}

// Call this function to handle expired refresh token
export const handleExpiredRefreshToken = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refresh-token');
    if (window.location.pathname !== '/sign-in') {
        window.location.href = '/sign-in';
    }
}
